<template>
    <div class="MobBasics" v-if="userInfo">
       <div class="basic-text">
      <div>
        <i class="iconfont icon-fanhui" @click="back"></i>
      </div>
      <div class="title">基本资料</div>
    </div>
    <div class="items">
      <div>我的昵称</div>
      <div>
        {{ userInfo.realName }} <i class="iconfont icon-xiangyoujiantou"></i>
      </div>
    </div>
    <div class="items">
      <div>我的邮箱</div>
      <div>
        {{ userInfo.email }} <i class="iconfont icon-xiangyoujiantou"></i>
      </div>
    </div>
    <div class="items">
      <div>会员类型</div>
      <div>
        {{ userInfo.groupTitle }} <i class="iconfont icon-xiangyoujiantou"></i>
      </div>
    </div>
    <div class="items">
      <div>手机绑定</div>
      <div>
        {{ userInfo.phone }} <i class="iconfont icon-xiangyoujiantou"></i>
      </div>
    </div>
    </div>
</template>

<script>
export default {
    name:"MobBasics",
    computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    back() {
      window.history.go(-1);
    },
  },
}
</script>

<style lang="less">
.MobBasics{
      background: #f4f5f7;
  height: 100vh;
     .basic-text {
    background: white;
    padding: 14px 0px;
    display: flex;
    &:first-child {
      padding-left: 20px;
    }
    & :nth-child(2) {
      margin: 0 auto;
      position: relative;
      left: -20px;
    }
  }
  .items {
    background: white;
    padding: 18px 20px;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    .iconfont {
      color: #c7c7c7;
      font-weight: bold;
    }
    & :last-child {
      color: #c7c7c7;
    }
  }
  .items:nth-child(2) {
    margin-top: 10px;
  }
}
</style>